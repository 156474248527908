<template>
  <div class="home" id="home">
    <div class="banner" id="particles-js">
      <canvas class="particles-js-canvas-el"></canvas>
      <b-container class="d-flex justify-center"
                   v-waypoint="{active: !aniMap.show, callback: (e) => cb(e, 0),  options}">
        <div class="cnt">
          <transition
              name="custom-classes-transition"
              enter-active-class="animated slideInLeft"
          >
            <b-img class="d-none d-md-block" :src="require('../assets/images/home/t0.png')" v-if="aniMap.show"></b-img>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated slideInLeft"
          >
            <b-img class="d-block d-md-none" :src="require('../assets/images/home/t0-m.png')"
                   v-if="aniMap.show"></b-img>
          </transition>
          <div class="cont d-flex flex-wrap">
            <transition
                name="custom-classes-transition"
                enter-active-class="animated delay-2000 slideInLeft"
            >
              <a class="share" target="_blank" href="https://twitter.com/berry_data" v-if="aniMap.show">
                <b-img class="ico" :src="require('../assets/images/foot/twitter.png')"></b-img>
                <span>Twitter</span>
              </a>
            </transition>
            <transition
                name="custom-classes-transition"
                enter-active-class="animated delay-1500 slideInLeft"
            >
              <a class="share" target="_blank" href="https://t.me/berrydata" v-if="aniMap.show">
                <b-img class="ico" :src="require('../assets/images/foot/tel.png')"></b-img>
                <span>Telegram</span></a>
            </transition>
            <transition
                name="custom-classes-transition"
                enter-active-class="animated delay-1000 slideInLeft "
            >
              <a class="share" target="_blank" href="https://medium.com/berry-data-official" v-if="aniMap.show">
                <b-img class="ico" :src="require('../assets/images/foot/m.png')"></b-img>
                <span>Medium</span></a>
            </transition>
            <transition
                name="custom-classes-transition"
                enter-active-class="animated delay-500 slideInLeft"
            >
              <a class="share" target="_blank" href="https://github.com/berrydata" v-if="aniMap.show">
                <b-img class="ico" :src="require('../assets/images/foot/g.png')"></b-img>
                <span>Github</span>
              </a>
            </transition>
            <transition
                name="custom-classes-transition"
                enter-active-class="animated slideInLeft"
            >
              <a class="share" target="_blank" href="https://www.coingecko.com/en/coins/berry-data" v-if="aniMap.show">
                <b-img class="ico" :src="require('../assets/images/foot/ge.png')"></b-img>
                <span>CoinGecko</span>
              </a>
            </transition>
          </div>
        </div>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated slideInRight"
        >
          <div class="global" v-if="aniMap.show">
            <b-img :src="require('../assets/images/home/global.png')"></b-img>
          </div>
        </transition>

        <div class="mouse d-none d-md-block">
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-2000 slideInUp"
          >
            <b-img v-if="aniMap.show" width="24" :src="require('../assets/images/home/mouse.png')"></b-img>
          </transition>
        </div>
      </b-container>
    </div>
    <div class="art1">
      <b-container class="d-flex flex-column align-center"
                   v-waypoint="{active: !aniMap.show1, callback: (e) => cb(e, 1),  options}">
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 slideInUp"
        >
          <div class="text-center tit" v-if="aniMap.show1">
            <b-img :src="require('../assets/images/home/t1.png')"></b-img>
          </div>
        </transition>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 slideInUp"
        >
          <div class="txt1" v-if="aniMap.show1">Berry Data is a transparent community-veriﬁed price oracle on
            BSC(Binance Smart Chain). Berry
            Data provides a trustless and decentralized alternative for off-chain data. Also, it provides the
            infrastructure for decentralized applications to query off-chain data by properly incentivizing miners to
            provide data.
          </div>
        </transition>

        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 slideInUp"
        >
          <div class="txt2" v-if="aniMap.show1">Berry Data's ecosystem has four key components:</div>
        </transition>
        <b-row>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-1500 slideInLeft"
          >
            <b-col :md="3" :cols="12" v-if="aniMap.show1">
              <div class="card">
                <b-img class="ic" width="76" :src="require('../assets/images/home/ic1.png')"></b-img>
                <div class="card-title">
                  Miners
                </div>
                <div class="card-cont">
                  Miners are incentivized to submit data using inflationary rewards, and which data types they update
                  are
                  chosen based upon the rewards assigned to each query.
                </div>
              </div>
            </b-col>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-1000 slideInLeft"
          >
            <b-col :md="3" :cols="12" v-if="aniMap.show1">
              <div class="card">
                <b-img class="ic" width="76" :src="require('../assets/images/home/ic2.png')"></b-img>
                <div class="card-title">
                  Data Users
                </div>
                <div class="card-cont">
                  Users can access the latest data feeds by paying BRY as the service fee.
                </div>
              </div>
            </b-col>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-500 slideInLeft"
          >
            <b-col :md="3" :cols="12" v-if="aniMap.show1">
              <div class="card">
                <b-img class="ic" width="76" :src="require('../assets/images/home/ic3.png')"></b-img>
                <div class="card-title">
                  Data Feeds
                </div>
                <div class="card-cont">
                  In every 3 minutes the BERRY smart contract groups the top five most funded data types and creates a
                  PoW challenge for miners to solve.
                </div>
              </div>
            </b-col>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated slideInLeft"
          >
            <b-col :md="3" :cols="12" v-if="aniMap.show1">
              <div class="card">
                <b-img class="ic" width="76" :src="require('../assets/images/home/ic4.png')"></b-img>
                <div class="card-title">
                  Rewarding System
                </div>
                <div class="card-cont">
                  The miners risk losing this stake if they submit data that is successfully disputed by BRY
                  holders.
                </div>
              </div>
            </b-col>
          </transition>
        </b-row>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-1000 infinite slideInDown"
        >
          <div v-if="aniMap.show1" class="button"
               onclick="window.open('https://docs.berrydata.co/whitepaper/introduction');">
            learn more about Berry Data
            <b-img width="25" :src="require('../assets/images/home/arrow.png')"></b-img>
          </div>
        </transition>
      </b-container>
    </div>
    <div class="art2" v-waypoint="{active: !aniMap.show2, callback: (e) => cb(e, 2),  options}">
      <b-container class="d-flex flex-column align-center">
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 slideInUp"
        >
          <div class="text-center tit" v-if="aniMap.show2">
            <b-img :src="require('../assets/images/home/t2.png')"></b-img>
          </div>
        </transition>
        <b-row>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-500 slideInLeft"
          >
            <b-col :md="6" :cols="12" v-if="aniMap.show2">
              <b-img :src="require('../assets/images/home/art2.png')"></b-img>
            </b-col>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-500 slideInRight"
          >
            <b-col :md="6" :cols="12" v-if="aniMap.show2">
              <div class="txt-box">
                <div class="txt-h">Miners begin to work</div>
                <div class="txt">Miners submit their PoW solution and off-chain data points to the Seer contract.</div>
              </div>
              <div class="txt-box">
                <div class="txt-h">Data Request is Submitted</div>
                <div class="txt">The user submits a query to Berry Data to incentivize miners to choose this query over
                  other submissions.
                </div>
              </div>
              <div class="txt-box">
                <div class="txt-h">Wrong Data can be Disputed</div>
                <div class="txt">Anyone holding Berry governance token (BRY) can dispute the validity of a mined value
                  by
                  paying a dispute fee.
                </div>
              </div>
              <div class="txt-box">
                <div class="txt-h">Data Feeds are Generated and Distributed</div>
                <div class="txt">Every 3 minutes, Berry's smart contract selects the best-funded queries and provides a
                  new challenge for miners to solve.
                </div>
              </div>
            </b-col>
          </transition>
        </b-row>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-2000 slideInUp"
        >
          <div v-if="aniMap.show2" class="button"
               onclick="window.open('https://docs.berrydata.co/whitepaper/berry-token');">
            learn more about Berry work flow
            <b-img width="25" :src="require('../assets/images/home/arrow.png')"></b-img>
          </div>
        </transition>
      </b-container>
    </div>
    <div class="art3" v-waypoint="{active: !aniMap.show3, callback: (e) => cb(e, 3),  options}">
      <b-container class="d-flex flex-column align-center">
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 fadeInUp"
        >
          <div class="text-center tit" v-if="aniMap.show3">
            <b-img :src="require('../assets/images/home/t3.png')"></b-img>
          </div>
        </transition>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 fadeInUp"
        >
          <div v-if="aniMap.show3" class="txt1">BRY is Berry Data’s governance token. BRY is designed to empower the
            broader DeFi ecosystem to
            drive the creation of a fully transparent, community-owned, and open-source oracle platform on the Binance
            Smart Chain.
          </div>
        </transition>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 fadeInUp"
        >
          <div v-if="aniMap.show3" class="txt2">BRY has four key use cases:</div>
        </transition>
        <div class="cont text-center">
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-500 fadeInUp"
          >
            <b-img v-if="aniMap.show3" :src="require('../assets/images/home/art3.png')"></b-img>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-1000 zoomIn"
          >
            <div class="txt-box text-right t1" v-if="aniMap.show3">
              <div class="txt-h">Pay for Berry Data Feeds</div>
              <div class="txt">BRY token holders can use Berry Data’s data as well as access to live data streams and
                specific data.
              </div>
            </div>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-1500 zoomIn"
          >
            <div class="txt-box text-left t2" v-if="aniMap.show3">
              <div class="txt-h">Vote on Governance Issues</div>
              <div class="txt">Any issue pertaining to the association and its platform that has severe implications
                will
                be put up for voting by means of the governance token.
              </div>
            </div>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-2000 zoomIn"
          >
            <div class="txt-box text-right t3" v-if="aniMap.show3">
              <div class="txt-h">Validate Data Through Staking</div>
              <div class="txt">BRY tokens can be staked to incentivize the validation of existing oracles, scrapers,
                methodologies, and more.
              </div>
            </div>
          </transition>
          <transition
              name="custom-classes-transition"
              enter-active-class="animated delay-2500 zoomIn"
          >
            <div class="txt-box text-left t4" v-if="aniMap.show3">
              <div class="txt-h">Build Berry Ecosystem</div>
              <div class="txt">Berry Data implements a 24 percent ecosystem fund. This revenue stream will be managed by
                the Berry team and be well utilized to the development of Berry technology, partnership, and investor
                ecosystem.
              </div>
            </div>
          </transition>
        </div>
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-2000 fadeInUp"
        >
          <div v-if="aniMap.show3" class="button"
               onclick="window.open('https://docs.berrydata.co/whitepaper/berry-token');">
            learn more about Token Model
            <b-img width="25" :src="require('../assets/images/home/arrow-w.png')"></b-img>
          </div>
        </transition>
      </b-container>
    </div>
    <div class="art4" v-waypoint="{active: !aniMap.show4, callback: (e) => cb(e, 4),  options}">
      <b-container class="d-flex flex-column align-center">
        <transition
            name="custom-classes-transition"
            enter-active-class="animated delay-500 fadeInUp"
        >
          <div class="text-center tit" v-if="aniMap.show4">
            <b-img :src="require('../assets/images/home/t4.png')"></b-img>
          </div>
        </transition>
        <b-row>
          <div class="cont">
            <transition-group enter-active-class="animated delay-500 fadeInUp" tag="div">
              <div class="box" v-for="item in 17" :key="item" v-show="aniMap.show4">
                <b-img :src="require(`../assets/images/band/${item}.png`)"></b-img>
              </div>
            </transition-group>
          </div>
        </b-row>

      </b-container>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {},
  data() {
    return {
      options: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1]
      },
      aniMap: {
        show: false,
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  },
  methods: {
    cb({going, direction}, idx) {
      // console.log(this.$waypointMap.GOING_IN, going, direction)
      if (direction === 'bottom') return;
      if (this.$waypointMap.GOING_IN === going) {
        this.aniMap[`show${idx ? idx : ''}`] = true
      } else {
        this.aniMap[`show${idx ? idx : ''}`] = false

      }
    }
  },
  mounted() {
    particlesJS('particles-js',
        {
          "particles": {
            "number": {
              "value": 160,//数量
              "density": {
                "enable": true, //启用粒子的稀密程度
                "value_area": 800 //区域散布密度大小
              }
            },
            "color": {
              "value": "#880D85" //原子的颜色
            },
            "shape": {
              "type": "circle", //原子的形状 "circle" ,"edge" ,"triangle" ,"polygon" ,"star" ,"image" ,["circle", "triangle", "image"]
              "stroke": {
                "width": 0, //原子的宽度
                "color": "#362cff" //原子颜色
              },
              "polygon": {
                "nb_sides": 5 // 原子的多边形边数
              },
              "image": {
                "src": "img/github.svg", // 原子的图片可以使用自定义图片 "assets/img/yop.svg" , "http://mywebsite.com/assets/img/yop.png"
                "width": 100, //图片宽度
                "height": 100 //图片高度
              }
            },
            "opacity": {
              "value": 1, //不透明度
              "random": true, //随机不透明度
              "anim": {
                "enable": true, //渐变动画
                "speed": 1, // 渐变动画速度
                "opacity_min": 0, //渐变动画不透明度
                "sync": true
              }
            },
            "size": {
              "value": 3, //原子大小
              "random": true, // 原子大小随机
              "anim": {
                "enable": false, // 原子渐变
                "speed": 4, //原子渐变速度
                "size_min": 0.3,
                "sync": false
              }
            },
            "line_linked": {
              "enable": false, //连接线
              "distance": 150, //连接线距离
              "color": "#ffffff", //连接线颜色
              "opacity": 0.4, //连接线不透明度
              "width": 1 //连接线的宽度
            },
            "move": {
              "enable": true, //原子移动
              "speed": 1, //原子移动速度
              "direction": "none", //原子移动方向   "none" ,"top" ,"top-right" ,"right" ,"bottom-right" ,"bottom" ,"bottom-left" ,"left" ,"top-left"
              "random": true, //移动随机方向
              "straight": false, //直接移动
              "out_mode": "out", //是否移动出画布
              "bounce": false, //是否跳动移动
              "attract": {
                "enable": false, // 原子之间吸引
                "rotateX": 600, //原子之间吸引X水平距离
                "rotateY": 600  //原子之间吸引Y水平距离
              }
            }
          },
          "interactivity": {
            "detect_on": "canvas", //原子之间互动检测 "canvas", "window"
            "events": {
              "onhover": {
                "enable": true, //悬停
                "mode": "bubble" //悬停模式      "grab"抓取临近的,"bubble"泡沫球效果,"repulse"击退效果,["grab", "bubble"]
              },
              "onclick": {
                "enable": false,  //点击效果
                "mode": "repulse"  //点击效果模式   "push" ,"remove" ,"bubble" ,"repulse" ,["push", "repulse"]
              },
              "resize": true // 互动事件调整
            },
            "modes": {
              "grab": {
                "distance": 100, //原子互动抓取距离
                "line_linked": {
                  "opacity": 0.8  //原子互动抓取距离连线不透明度
                }
              },
              "bubble": {
                "distance": 250, //原子抓取泡沫效果之间的距离
                "size": 4, // 原子抓取泡沫效果之间的大小
                "duration": 2, //原子抓取泡沫效果之间的持续事件
                "opacity": 1, //原子抓取泡沫效果透明度
                "speed": 3
              },
              "repulse": {
                "distance": 400, //击退效果距离
                "duration": 0.4 //击退效果持续事件
              },
              "push": {
                "particles_nb": 4 //粒子推出的数量
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true
        }
    );
  }
}
</script>
<style lang="less">
.home {
  .banner {
    height: 760px;
    background-color: #2B0629;
    position: relative;

    .container {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .mouse {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
    }

    .cnt, .global {
      display: inline-flex;
      flex-direction: column;
      //justify-content: center;
      //height: 760px;
    }

    .cnt {
      margin-top: 155px;

      .share {
        width: 120px;
        //padding-left: 15px;
        //padding-right: 20px;
        height: 40px;
        border-radius: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #880D85;
        margin-right: 8px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: #CA0FC5;
        }

        &:last-of-type {
          margin-right: 0;
          width: 140px;
        }

        img {
          width: 28px;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .global {
      margin-left: -90px;
      margin-top: 84px;
    }
  }

  .art1 {
    height: 871px;
    background-color: #F7F2F6;

    .tit {
      padding: 75px 0 50px;
    }

    .txt2 {
      margin-top: 16px;
      margin-bottom: 80px;
      align-self: flex-start;
    }

    .card {
      width: 278px;
      height: 293px;
      background: linear-gradient(to bottom, #F7F2F6, #FFF);
      text-align: center;
      padding: 35px;
      border: none;
      box-shadow: 0 0 2px #E4D9E2;
      border-radius: 14px;

      .ic {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &-title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      &-cont {
        color: #826D81;
      }
    }

    .button {
      margin-top: 88px;
    }
  }

  .art2 {
    background: #fff;
    height: 830px;

    .tit {
      padding: 75px 0 50px;
    }

    .txt-box {
      margin-bottom: 30px;

      .txt-h {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1;
      }
    }

    .button {
      margin-top: -25px;
    }
  }

  .art3 {
    color: #fff;
    background: #4D3C64;
    height: 1109px;

    .tit {
      padding: 80px 0 80px;
    }

    .txt1, .txt2 {
      color: #fff;
    }

    .txt2 {
      margin-top: 8px;
      align-self: flex-start;
    }

    .cont {
      margin-top: 60px;
      position: relative;
      width: 470px;

      .txt-box {
        max-width: 335px;

        .txt-h {
          font-size: 22px;
          font-weight: bold;
          line-height: 1;
          color: #8BC111;
        }

        .txt {
          color: #fff;
          margin-top: 6px;
          line-height: 1.2;
        }
      }

      .t1 {
        position: absolute;
        top: 70px;
        left: -10px;
        transform: translateX(-100%);
      }

      .t2 {
        position: absolute;
        top: 70px;
        right: -10px;
        transform: translateX(100%);
      }

      .t3 {
        position: absolute;
        bottom: 10px;
        left: -10px;
        transform: translateX(-100%);
      }

      .t4 {
        position: absolute;
        bottom: -46px;
        right: -10px;
        transform: translateX(100%);
      }
    }

    .button {
      margin-top: 150px;
      color: #fff;
      background-color: #8A7B9F;
      border-color: #8A7B9F;

    }
  }

  .art4 {
    height: 850px;
    background: #F7F2F6 url("../assets/images/home/art4.png") top center no-repeat;

    .tit {
      padding: 90px 0 50px;
    }

    .cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .box {
        width: 220px;
        height: 82px;
        background-color: #880D85;
        border-radius: 8px;
        margin-left: 20px;
        margin-top: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:nth-of-type(1), &:nth-of-type(6), &:nth-of-type(11) {
          margin-left: 0;
        }
      }
    }

    .mt-24 {
      margin-top: 24px;
    }
  }

  .button {
    padding: 0 30px;
    height: 47px;
    border-radius: 47px;
    font-size: 14px;
    border: 1px solid #880D85;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #880D85;
    cursor: pointer;
    position: relative;
    z-index: 1;

    img {
      margin-left: 20px;
    }
  }
}

@media (max-width: 768px) {

  #home {
    .banner {
      height: 484px;
      overflow: hidden;
      position: relative;

      .container {
        flex-direction: column;
        justify-content: flex-start;

        .cnt {
          width: 350px;
          height: auto;
          justify-self: flex-start;
          margin-top: 34px;
          margin-left: 0;
        }
      }

      .cont {
        margin-left: 20px;
      }

      .share {
        margin-top: 13px;
      }

      .global {
        position: absolute;
        bottom: -170px;
        left: 0;
        right: 0;
        font-size: 0;
        display: flex;
        width: 100%;
        margin: 0;
        height: auto;
        z-index: -1;

        img {
          width: 120%;
          margin-left: -10%;
          //margin: 0 auto;
        }
      }
    }

    .art1 {
      padding: 30px 25px;
      height: auto;

      .tit {
        padding-top: 0;
        padding-bottom: 40px;
        width: 252px;
        font-size: 0;

        img {
          width: 100%;
        }
      }

      .card {
        margin-bottom: 67px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 0;
        height: 285px;

        &:last-of-type {
          margin-bottom: 60px;
        }
      }

      .button {
        margin-top: 0px;
        margin-bottom: 35px;
      }
    }

    .art2 {
      height: auto;

      .tit {
        padding: 40px 0;
      }

      img {
        max-width: 100%;
      }

      .txt-box {
        padding-left: 15px;
        padding-right: 15px;
      }

      .button {
        margin-top: 24px;
        margin-bottom: 86px;
      }
    }

    .art3 {
      height: auto;
      padding: 0 15px;

      img {
        max-width: 100%;
      }

      .tit {
        width: 180px;
        padding: 40px 0;
      }

      .cont {
        width: 100%;

        img {
          width: 271px;
          margin-bottom: 36px;
        }

        .t1, .t2, .t3, .t4 {
          position: static;
          transform: translate(0, 0);
          text-align: left !important;
          max-width: 100%;
          margin-bottom: 20px;

          .txt-h {
            font-size: 20px;
          }
        }
      }

      .button {
        margin-top: 15px;
        margin-bottom: 78px;
      }
    }

    .art4 {
      height: auto;

      img {
        max-width: 100%;
      }

      .tit {
        width: 170px;
        padding: 40px 0;
      }

      .cont {
        padding: 0 40px;
        margin-bottom: 80px;

        .box {
          height: 86px;
          margin-left: 0;
          margin-top: 20px;

          &:first-of-type {
            margin-top: 0;
          }
        }

        .mt-24 {
          margin-top: 0;
        }
      }
    }

    .button {
      font-size: 12px;
      font-weight: bold;
    }
  }

}
</style>
